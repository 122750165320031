import cn from 'classnames'
import { ReactNode } from 'react'

export type Props = {
  label: string
  subLabel?: string
  size?: 'default' | 'large'
  asterisk?: boolean // to show asterisk next to label
  uppercase?: boolean
  as?: 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'label' | 'p' | 'span'
  children?: ReactNode
  readonly?: boolean
  icon?: ReactNode
  disabled?: boolean
  labelClassName?: string
  hidden?: boolean // show only children when hidden = true
  wrapperClassName?: string
}

const TextLabel = (props: Props) => {
  const {
    size = 'default',
    asterisk,
    children,
    label,
    uppercase = true,
    as: Component = 'div',
    readonly,
    subLabel,
    icon,
    disabled,
    labelClassName,
    hidden,
    wrapperClassName,
  } = props
  const isDefault = size === 'default'
  const isLarge = size === 'large'

  if (hidden) return children
  return (
    <div className="group w-full">
      <Component // Use the variable as the component to render
        className={cn(
          'flex w-full flex-col',
          disabled && 'opacity-50',
          isDefault && 'text-[10px] lg:text-[12px]',
          isLarge && 'text-[12px] lg:text-[16px]',
          readonly ? 'cursor-default' : 'cursor-pointer',
          wrapperClassName,
        )}
      >
        {/* label */}
        <span className="inline-flex w-full items-center font-medium text-[#808080]">
          <span className={cn('font-bold tracking-wider', uppercase && 'uppercase', labelClassName)}>{label}</span>
          {subLabel && <span className="ml-1">{subLabel}</span>}
          {asterisk && <span className="ml-1 text-[#00D973]">*</span>}
          {icon && <span className="ml-1">{icon}</span>}
        </span>
        {/* children */}
        {children}
      </Component>
    </div>
  )
}

export default TextLabel
